import { useEffect } from 'react';
import Head from 'next/head';
import Router from 'next/router';
import { DefaultSeo } from 'next-seo';
import * as Sentry from '@sentry/node';

import * as gtag from 'lib/gtag';
import Loading from 'components/Loading';

import SEO from 'next-seo.config';
import 'styles/index.css';

Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN
});

Router.events.on('routeChangeStart', () => <Loading.Full />);

export function reportWebVitals({ id, name, label, value }) {
  gtag.event({
    category: label === 'web-vital' ? 'web_vitals' : 'nextjs_custom_metric',
    action: name?.toLowerCase().replace(/-/g, '_').replace(/\./g, ''), // primitive formatting
    label: id, // id unique to current page load
    value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    non_interaction: true // avoids affecting bounce rate.
  });
}

function App({ Component, pageProps, err }) {
  // Google Analytics
  useEffect(() => {
    const handleRouteChange = url => {
      gtag.pageview(url);
    };
    Router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5, viewport-fit=cover, shrink-to-fit=no"
        />
        <meta name="HandheldFriendly" c67ontent="true" />
        <meta name="theme-color" content="#FFF" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
      </Head>
      <DefaultSeo {...SEO} />
      <div className="antialiased leading-tight">
        <Component {...pageProps} err={err} />
      </div>
      <div id="drawer-root" />
      <div id="modal-root" />
    </>
  );
}

export default App;
