import { DEFAULT_TITLE } from 'utils/constants';

export default {
  titleTemplate: '%s | medmap.io',
  title: DEFAULT_TITLE,
  description:
    'Interactive map of medical residency and fellowship locations for The Match. Compare different specialties for Couples Match. Locate medical internships, residencies, and fellowships in the US and US Territories.',
  openGraph: {
    type: 'website',
    locale: 'en_IE',
    url: 'https://www.medmap.io/',
    site_name: 'medmap.io'
  },
  twitter: {
    handle: '@medmap_io',
    site: '@medmap_io',
    cardType: 'summary_large_image'
  }
};
